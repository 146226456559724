import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const LandingPage = () => {
  const [professionalMode, setProfessionalMode] = useState(false);

  // Background style based on theme
  const backgroundStyle = professionalMode
    ? { backgroundColor: '#f8f9fa' }
    : {
        backgroundImage: 'radial-gradient(circle, #00FFFF 2px, transparent 2px)',
        backgroundSize: '24px 24px',
        backgroundColor: '#e0f7fa'
      };

  // Base card style class names
  const baseCardClass = 'p-6 mb-6';
  // Theme-specific card styles
  const cardStyle = professionalMode
    ? `${baseCardClass} bg-white rounded-lg shadow`
    : `${baseCardClass} bg-white shadow-md border-4 border-black`;

  const keyFindingsCardStyle = professionalMode
    ? 'bg-gray-50 rounded-lg p-5 shadow hover:transform hover:scale-105 transition-transform duration-300'
    : 'rounded-none p-5 shadow-md border-2 border-black hover:transform hover:scale-105 transition-transform duration-300';

  return (
    <div className={`min-h-screen relative ${professionalMode ? 'bg-gray-50' : 'bg-teal-100'}`} style={backgroundStyle}>
      <Helmet>
        <title>VibeCheck: Discover and Quantify Qualitative Differences in LLMs</title>
        <link rel="icon" href={`${process.env.PUBLIC_URL}/favicon.ico`} />
        <link href="https://fonts.googleapis.com/css2?family=VT323&family=Press+Start+2P&family=Inter:wght@400;500;600;700&display=swap" rel="stylesheet" />
      </Helmet>

      {/* Navigation Header (Same as before) */}
      <header className={`${professionalMode ? 'bg-white shadow-md' : 'bg-yellow-300 shadow-lg border-b-4 border-t-4 border-black'} py-4 sticky top-0 z-50`}>
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between">
            <div className="flex items-center justify-between">
              <h1 className={`text-3xl font-bold ${professionalMode ? 'text-gray-800' : 'text-black'}`}
                  style={professionalMode ? { fontFamily: "'Inter', sans-serif" } : { fontFamily: "'Press Start 2P', cursive" }}>
                VibeCheck
              </h1>
              <div className="flex items-center md:hidden">
                <button
                  onClick={() => setProfessionalMode(!professionalMode)}
                  className={`px-3 py-1 rounded-md text-sm font-medium transition-colors ${
                    professionalMode
                      ? 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200'
                      : 'bg-purple-400 text-white hover:bg-purple-500 border border-black'
                  }`}
                >
                  {professionalMode ? '90s Vibe' : 'Academic Vibe'}
                </button>
              </div>
            </div>
            <nav className="flex items-center justify-between mt-4 md:mt-0">
              <ul className="flex flex-wrap gap-6">
                {/* Added Blog Link */}
                <li>
                  <Link to="/blog" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-black hover:text-orange-500'} transition-colors font-bold hover:scale-105 inline-block transform`}>Blog</Link>
                </li>
                 <li>
                  <Link to="/visualizer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-black hover:text-orange-500'} transition-colors font-bold hover:scale-105 inline-block transform`}>Vibe Visualizer</Link>
                </li>
                <li>
                  <a href="https://arxiv.org/abs/2410.12851" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-black hover:text-orange-500'} transition-colors font-bold hover:scale-105 inline-block transform`} target="_blank" rel="noopener noreferrer">Paper</a>
                </li>
                <li>
                  <a href="https://github.com/lisadunlap/VibeCheck" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-black hover:text-orange-500'} transition-colors font-bold hover:scale-105 inline-block transform`} target="_blank" rel="noopener noreferrer">GitHub</a>
                </li>
                <li>
                  <a href="https://huggingface.co/datasets/lmarena-ai/Llama-3-70b-battles" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-black hover:text-orange-500'} transition-colors font-bold hover:scale-105 inline-block transform`} target="_blank" rel="noopener noreferrer">Dataset</a>
                </li>
              </ul>
              <div className="hidden md:block ml-6">
                <button
                  onClick={() => setProfessionalMode(!professionalMode)}
                  className={`px-3 py-1 rounded-md text-sm font-medium transition-colors ${
                    professionalMode
                      ? 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200'
                      : 'bg-purple-400 text-white hover:bg-purple-500 border border-black'
                  }`}
                >
                  {professionalMode ? '90s Vibe' : 'Academic Vibe'}
                </button>
              </div>
            </nav>
          </div>
        </div>
      </header>

      {/* Landing Page Content */}
      <main className="container mx-auto px-4 py-8 max-w-4xl">

        {/* Title and Authors */}
        <div className="mb-8 text-center relative">
          {!professionalMode && (
            <>
              <div className="absolute -top-6 -left-6 w-24 h-24 bg-lime-400 rounded-full opacity-50"></div>
              <div className="absolute -bottom-6 -right-6 w-32 h-32 bg-orange-400 rounded-full opacity-50"></div>
            </>
          )}
          <h1 className={`text-3xl md:text-4xl font-bold mb-6 relative z-10 ${professionalMode ? 'text-gray-800' : ''}`}
              style={professionalMode ? {} : { textShadow: '1px 1px 0 #FF00FF, -1px -1px 0 #00FFFF' }}>
            VibeCheck: Discover and Quantify Qualitative Differences in Large Language Models
          </h1>
          <div className="flex flex-wrap justify-center gap-3 text-lg relative z-10">
             <a href="https://www.lisabdunlap.com/" target="_blank" rel="noopener noreferrer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-green-600 hover:text-orange-500'} font-bold hover:scale-105 inline-block transform transition-all`}>Lisa Dunlap</a>
            <span>•</span>
            <a href="https://www.linkedin.com/in/krishna-mandal/" target="_blank" rel="noopener noreferrer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-green-600 hover:text-orange-500'} font-bold hover:scale-105 inline-block transform transition-all`}>Krishna Mandal</a>
            <span>•</span>
            <a href="https://darrellgroup.github.io/" target="_blank" rel="noopener noreferrer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-green-600 hover:text-orange-500'} font-bold hover:scale-105 inline-block transform transition-all`}>Trevor Darrell</a>
            <span>•</span>
            <a href="https://jsteinhardt.stat.berkeley.edu/" target="_blank" rel="noopener noreferrer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-green-600 hover:text-orange-500'} font-bold hover:scale-105 inline-block transform transition-all`}>Jacob Steinhardt</a>
            <span>•</span>
            <a href="https://joeygonzalez.com/" target="_blank" rel="noopener noreferrer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-green-600 hover:text-orange-500'} font-bold hover:scale-105 inline-block transform transition-all`}>Joseph E Gonzalez</a>
          </div>
        </div>

         {/* Introduction / Abstract Section */}
         <section className={cardStyle} style={!professionalMode ? { backgroundColor: '#FDFD96' } : {}}>
          <h2 className={`text-2xl font-bold mb-4 ${professionalMode ? 'text-gray-800' : 'text-black'}`}
              style={professionalMode ? {} : { textShadow: '1px 1px 0 #FF00FF' }}>
            What's the Vibe?
          </h2>
          <div className={`prose max-w-none ${professionalMode ? 'prose-indigo' : ''}`}>
            <p>
              Large language models (LLMs) have distinct personalities or "vibes" – like their tone or writing style – that go beyond simple correctness. These vibes influence how users perceive and prefer different models, but they're hard to pin down and measure. Traditional evaluations often miss these nuances, focusing mainly on accuracy.
            </p>
            <p>
              <strong>Introducing VibeCheck:</strong> A system designed to automatically identify and quantify these qualitative differences, or "vibes," between pairs of LLMs.
            </p>
          </div>
        </section>

        {/* Combined How it Works Section with Method Diagram */}
        <section className={cardStyle} style={{ backgroundColor: '#FFFFFF' }}>
           <h2 className={`text-2xl font-bold mb-4 ${professionalMode ? 'text-gray-800' : 'text-black'}`}
              style={professionalMode ? {} : { textShadow: '1px 1px 0 #00FFFF' }}>
            How it Works
          </h2>
          
          {/* Method Diagram - Added from Method Overview section */}
          <div className="flex items-center justify-center mb-4">
             <img
               src={`${process.env.PUBLIC_URL}/method.jpeg`}
               alt="VibeCheck Method Diagram"
               className="w-full h-auto max-w-2xl shadow-md"
               onError={(e) => {
                 e.target.onerror = null;
                 e.target.src = "/method.jpeg";
               }}
             />
           </div>
           <div className={`prose max-w-none mb-6 ${professionalMode ? 'prose-indigo' : ''}`}>
             <p>
               VibeCheck discovers, validates, and analyzes qualitative differences ("vibes") between LLMs using an iterative process involving LLMs to propose and judge vibes.
             </p>
           </div>
          
          <div className={`prose max-w-none ${professionalMode ? 'prose-indigo' : ''}`}>
            <ol className="list-decimal pl-5 space-y-2">
              <li>
                <strong>Discovery:</strong> VibeCheck analyzes model outputs to discover potential vibes – axes along which the models differ (e.g., formal vs. friendly).
              </li>
              <li>
                <strong>Validation:</strong> It uses a panel of LLM judges to score how model outputs rank on these discovered vibes.
              </li>
               <li>
                <strong>Quantification:</strong> VibeCheck measures each vibe based on three key criteria:
                <ul className="list-disc pl-5 mt-1">
                  <li><strong>Well-defined:</strong> Do judges agree on the vibe?</li>
                  <li><strong>Differentiating:</strong> Does the vibe consistently distinguish between the two models?</li>
                  <li><strong>User-aligned:</strong> Does the vibe correlate with user preferences?</li>
                </ul>
              </li>
            </ol>
          </div>
        </section>

        {/* Why VibeCheck / Key Findings Section */}
        <section className={cardStyle} style={!professionalMode ? { backgroundColor: '#FFB347' } : {}}>
          <h2 className={`text-2xl font-bold mb-4 ${professionalMode ? 'text-gray-800' : 'text-black'}`}
              style={professionalMode ? {} : { textShadow: '1px 1px 0 #FF6347' }}>
            Why VibeCheck?
          </h2>
           <div className={`prose max-w-none mb-4 ${professionalMode ? 'prose-indigo' : ''}`}>
             <p>
                VibeCheck helps us understand the subtle characteristics that make models unique. It provides insights beyond standard benchmarks, revealing <em>how</em> models differ in ways that matter to users. We've validated that VibeCheck identifies vibes consistent with human observations.
             </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className={keyFindingsCardStyle} style={!professionalMode ? { backgroundColor: '#C1E1C1'} : {}}>
              <h3 className="font-semibold mb-2 text-black">Check the Blog</h3>
              <p>Read our <Link to="/blog" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-blue-600 font-bold underline hover:text-orange-500'}`}>blog post</Link> for a deeper dive into the findings and methodology.</p>
            </div>
            <div className={keyFindingsCardStyle} style={!professionalMode ? { backgroundColor: '#AEC6CF'} : {}}>
              <h3 className="font-semibold mb-2 text-black">Explore the Vibes</h3>
              <p>Explore model vibes and see example outputs with our <Link to="/visualizer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-blue-600 font-bold underline hover:text-orange-500'}`}>interactive visualizer</Link>.</p>
            </div>
          </div>
        </section>

        {/* Citation */}
        <section className={cardStyle} style={!professionalMode ? { backgroundColor: '#FF9999' } : {}}>
          <h2 className={`text-2xl font-bold mb-4 ${professionalMode ? 'text-gray-800' : 'text-black'}`}
              style={professionalMode ? {} : { textShadow: '1px 1px 0 #00FF00' }}>
            Citation
          </h2>
          <div className={`${professionalMode ? 'bg-gray-100' : 'bg-yellow-200'} p-3 font-mono text-sm overflow-x-auto ${professionalMode ? 'border rounded' : 'border-2 border-dashed border-black'}`}>
            <pre>
{`@inproceedings{dunlap2025vibecheck,
  title={VibeCheck: Discover and Quantify Qualitative Differences in Large Language Models},
  author={Dunlap, Lisa and Mandal, Krishna and Darrell, Trevor and Steinhardt, Jacob and Gonzalez, Joseph E},
  booktitle={International Conference on Learning Representations (ICLR)},
  year={2025},
  url={https://lisadunlap.github.io/VibeCheck/}
}`}
            </pre>
          </div>
        </section>

      </main>

      {/* Footer (Same as before) */}
       <footer className={professionalMode ? "bg-gray-800 text-white py-6" : "bg-black text-white py-6 border-t-4 border-yellow-400"}>
        <div className="container mx-auto px-4 text-center">
          {professionalMode ? (
            <p>&copy; {new Date().getFullYear()} VibeCheck Team. All rights reserved.</p>
          ) : (
            <div className="scrolling-text-container overflow-hidden whitespace-nowrap">
              <div className="scrolling-text inline-block animate-scroll">
                &copy; {new Date().getFullYear()} VibeCheck Team. All rights reserved. ☆彡 ★彡 ☆彡 ★彡&nbsp;
                &copy; {new Date().getFullYear()} VibeCheck Team. All rights reserved. ☆彡 ★彡 ☆彡 ★彡&nbsp;
              </div>
            </div>
          )}
        </div>
      </footer>
      {/* Add CSS for scrolling animation if not already present */}
      <style jsx global>{`
        .scrolling-text-container {
          width: 100%;
        }
        @keyframes scroll {
          0% { transform: translateX(0); }
          100% { transform: translateX(-50%); } /* Adjust if text length changes significantly */
        }
        .animate-scroll {
          animation: scroll 20s linear infinite; /* Adjust duration as needed */
        }
      `}</style>
    </div>
  );
};

export default LandingPage;