import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const BlogPage = () => {
  // State for theme - assumes you want the theme toggle here too
  // If header/footer are separate components, manage state globally (e.g., Context API)
  const [professionalMode, setProfessionalMode] = useState(false);

  // Background style based on theme
  const backgroundStyle = professionalMode
    ? { backgroundColor: '#f8f9fa' }
    : {
        backgroundImage: 'radial-gradient(circle, #FFD700 2px, transparent 2px)', // Gold dots for blog?
        backgroundSize: '24px 24px',
        backgroundColor: '#fffacd' // Lemon Chiffon background?
      };

  // Styles for different sections based on theme
  const cardStyle = professionalMode
    ? 'bg-white rounded-lg shadow p-6 mb-6'
    : 'bg-white shadow-md border-4 border-black p-6 mb-6';

  return (
    <div className={`min-h-screen relative ${professionalMode ? 'bg-gray-50' : 'bg-lemon-100'}`} style={backgroundStyle}>
      <Helmet>
        <title>Blog: Understanding the "Vibe" of LLMs with VibeCheck</title>
        <link rel="icon" href={`${process.env.PUBLIC_URL}/favicon.ico`} />
        <link href="https://fonts.googleapis.com/css2?family=VT323&family=Press+Start+2P&family=Inter:wght@400;500;600;700&display=swap" rel="stylesheet" />
      </Helmet>

      {/* Navigation Header (Reused - consider making this a shared component) */}
      <header className={`${professionalMode ? 'bg-white shadow-md' : 'bg-yellow-300 shadow-lg border-b-4 border-t-4 border-black'} py-4 sticky top-0 z-50`}>
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between">
            <div className="flex items-center justify-between">
              {/* Link back to landing page */}
               <Link to="/" className={`text-3xl font-bold ${professionalMode ? 'text-gray-800' : 'text-black'} hover:opacity-80`}
                  style={professionalMode ? { fontFamily: "'Inter', sans-serif" } : { fontFamily: "'Press Start 2P', cursive" }}>
                VibeCheck
              </Link>
              <div className="flex items-center md:hidden">
                <button
                  onClick={() => setProfessionalMode(!professionalMode)}
                  className={`px-3 py-1 rounded-md text-sm font-medium transition-colors ${
                    professionalMode
                      ? 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200'
                      : 'bg-purple-400 text-white hover:bg-purple-500 border border-black'
                  }`}
                >
                  {professionalMode ? '90s Vibe' : 'Academic Vibe'}
                </button>
              </div>
            </div>
            <nav className="flex items-center justify-between mt-4 md:mt-0">
              <ul className="flex flex-wrap gap-6">
                 {/* Updated links for blog context */}
                 <li>
                  <Link to="/" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-black hover:text-orange-500'} transition-colors font-bold hover:scale-105 inline-block transform`}>Home</Link>
                </li>
                 <li>
                  <Link to="/visualizer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-black hover:text-orange-500'} transition-colors font-bold hover:scale-105 inline-block transform`}>Vibe Visualizer</Link>
                </li>
                <li>
                  <a href="https://arxiv.org/abs/2410.12851" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-black hover:text-orange-500'} transition-colors font-bold hover:scale-105 inline-block transform`} target="_blank" rel="noopener noreferrer">Paper</a>
                </li>
                <li>
                  <a href="https://github.com/lisadunlap/VibeCheck" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-black hover:text-orange-500'} transition-colors font-bold hover:scale-105 inline-block transform`} target="_blank" rel="noopener noreferrer">GitHub</a>
                </li>
                 <li>
                  <a href="https://huggingface.co/datasets/lmarena-ai/Llama-3-70b-battles" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-black hover:text-orange-500'} transition-colors font-bold hover:scale-105 inline-block transform`} target="_blank" rel="noopener noreferrer">Dataset</a>
                </li>
              </ul>
              <div className="hidden md:block ml-6">
                <button
                  onClick={() => setProfessionalMode(!professionalMode)}
                  className={`px-3 py-1 rounded-md text-sm font-medium transition-colors ${
                    professionalMode
                      ? 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200'
                      : 'bg-purple-400 text-white hover:bg-purple-500 border border-black'
                  }`}
                >
                  {professionalMode ? '90s Vibe' : 'Academic Vibe'}
                </button>
              </div>
            </nav>
          </div>
        </div>
      </header>

      {/* Blog Post Content */}
      <main className="container mx-auto px-4 py-8 max-w-4xl">
        {/* Title and Authors */}
        <div className="mb-8 text-center">
           <h1 className={`text-3xl md:text-4xl font-bold mb-4 ${professionalMode ? 'text-gray-800' : ''}`}
              style={professionalMode ? {} : { textShadow: '1px 1px 0 #FF00FF, -1px -1px 0 #00FFFF' }}>
             Beyond Correctness: Understanding the "Vibe" of LLMs with VibeCheck
          </h1>
           <p className={`text-lg mb-6 ${professionalMode ? 'text-gray-600' : 'text-gray-700'}`}>
             Posted by the VibeCheck Team
           </p>
           {/* Optional: Author links like on landing page */}
        </div>

         {/* Introduction */}
        <section className={cardStyle} style={!professionalMode ? { backgroundColor: '#FDFD96' } : {}}>
           <h2 className={`text-2xl font-bold mb-4 ${professionalMode ? 'text-gray-800' : 'text-black'}`}
               style={professionalMode ? {} : { textShadow: '1px 1px 0 #FF6347' }}>
             We've All Felt It...
           </h2>
           <div className={`prose max-w-none ${professionalMode ? 'prose-indigo' : ''}`}>
             <p>
               You interact with different large language models (LLMs), and even if they give "correct" answers, they just <em>feel</em> different. One might be formal and direct, another friendly and conversational, maybe one uses more emojis, or structures its responses with markdown. These subtle characteristics – their tone, style, formatting quirks – are what we call the model's "vibe".
             </p>
             <p>
               While users intuitively pick up on these vibes and often base their preferences on them, standard LLM evaluations tend to focus narrowly on correctness metrics (like accuracy on benchmarks). This misses a huge part of the picture! How helpful an LLM <em>feels</em> often depends on whether its vibe fits the task and the user's expectations. A formal vibe might be great for writing code, but less so for brainstorming creative ideas.
             </p>
          </div>
        </section>

         {/* The Challenge */}
        <section className={cardStyle} style={!professionalMode ? { backgroundColor: '#FFB347' } : {}}>
           <h2 className={`text-2xl font-bold mb-4 ${professionalMode ? 'text-gray-800' : 'text-black'}`}
               style={professionalMode ? {} : { textShadow: '1px 1px 0 #FF00FF' }}>
             The Challenge: Defining and Measuring Vibes
           </h2>
           <div className={`prose max-w-none ${professionalMode ? 'prose-indigo' : ''}`}>
             <p>
               The problem is, how do you actually measure something as subjective as a "vibe"? It's easy to say "Llama-3 feels friendlier than GPT-4", but how can we systematically discover these differences and quantify their impact?
             </p>
           </div>
        </section>

         {/* Introducing VibeCheck */}
        <section className={cardStyle} style={{ backgroundColor: '#FFFFFF' }}>
           <h2 className={`text-2xl font-bold mb-4 ${professionalMode ? 'text-gray-800' : 'text-black'}`}
               style={professionalMode ? {} : { textShadow: '1px 1px 0 #00FFFF' }}>
             Introducing VibeCheck
           </h2>
           <div className={`prose max-w-none ${professionalMode ? 'prose-indigo' : ''}`}>
             <p>
               That's why we developed <strong>VibeCheck</strong>, a system to automatically discover and quantify the qualitative differences – the vibes – between pairs of LLMs. We wanted a way to move beyond just <em>feeling</em> the difference, to actually <em>measuring</em> it.
             </p>
             
             {/* Method Diagram */}
             <div className="flex items-center justify-center my-6">
               <img
                 src={`${process.env.PUBLIC_URL}/method.jpeg`}
                 alt="VibeCheck Method Diagram"
                 className="w-full h-auto max-w-2xl shadow-md"
                 onError={(e) => {
                   e.target.onerror = null;
                   e.target.src = "/method.jpeg";
                 }}
               />
             </div>
             
             <p>VibeCheck works in steps:</p>
             <ol className="list-decimal pl-5 space-y-2">
               <li>
                 <strong>Discovering Vibes:</strong> VibeCheck looks at outputs from two different models for the same prompts and asks an LLM (acting like a researcher) to identify axes of difference. For example, it might identify a "Formality" vibe, ranging from "casual/conversational" to "formal/sophisticated".
               </li>
               <li>
                 <strong>Scoring Vibes:</strong> Once potential vibes are identified, VibeCheck uses a panel of LLM judges to compare model outputs on each vibe axis. For a given prompt and pair of outputs, judges decide which output ranks higher on that specific vibe (e.g., "Which response is more friendly?").
               </li>
               <li>
                  <strong>Quantifying Utility:</strong> A vibe is only useful if it meets certain criteria. VibeCheck measures:
                 <ul className="list-disc pl-5 mt-1">
                   <li><strong>Well-defined:</strong> Do different judges consistently agree on how outputs rank on the vibe? (Measured using Cohen's Kappa).</li>
                   <li><strong>Differentiating:</strong> Does the vibe consistently separate the two models? Can we predict which model generated an output based on its vibe score? (Measured via separability score and model-matching accuracy).</li>
                   <li><strong>User-aligned:</strong> Does the vibe actually correlate with which model users prefer? (Measured via preference prediction accuracy).</li>
                 </ul>
               </li>
             </ol>
             <p>
               VibeCheck iteratively refines this process, focusing on examples where the current vibes don't explain the differences well, to discover even more nuanced distinctions.
             </p>
           </div>
         </section>

         {/* What Did VibeCheck Find? */}
         <section className={cardStyle} style={!professionalMode ? { backgroundColor: '#AEC6CF' } : {}}>
           <h2 className={`text-2xl font-bold mb-4 ${professionalMode ? 'text-gray-800' : 'text-black'}`}
              style={professionalMode ? {} : { textShadow: '1px 1px 0 #9370DB' }}> {/* Medium Purple shadow */}
             What Did VibeCheck Find?
           </h2>
           <div className={`prose max-w-none ${professionalMode ? 'prose-indigo' : ''}`}>
              <p>We ran VibeCheck in several scenarios:</p>
              <ul className="list-disc pl-5 space-y-2">
                <li>
                   <strong>Human vs. ChatGPT:</strong> VibeCheck successfully rediscovered many differences that human annotators had previously identified between human writing and GPT-3.5 outputs (like formality, use of anecdotes, and hedging).
                </li>
                 <li>
                   <strong>Llama-3 vs. GPT-4/Claude:</strong> Analyzing real-world user comparisons from Chatbot Arena, VibeCheck found distinct vibes. Llama-3 tends to be more enthusiastic and friendly, uses more typographic emphasis (like bold/italics) and examples, employs more humor, and is less likely to hedge or comment on ethics compared to GPT-4 and Claude-3-Opus. Crucially, many of these "Llama vibes" (like humor and formatting emphasis) positively correlated with user preference on Chatbot Arena, helping explain its popularity. VibeCheck's discovered vibes predicted model identity with 80% accuracy and user preference with 61% accuracy.
                 </li>
                 <li>
                   <strong>Getting more from existing benchmarks:</strong> We also applied VibeCheck to existing benchmarks where LLMs achieve similar accuracy metrics but differ in preference:
                   <ul className="list-circle pl-5 mt-1 space-y-1">
                      <li><strong>Summarization:</strong> Comparing Cohere Command X and TNLGv2, VibeCheck noted Command X adds clearer intros/conclusions and examples, vibes that aligned with LLM judge preferences.</li>
                      <li><strong>Math:</strong> Comparing GPT-4o and Llama-405b on math problems, VibeCheck found Llama-405b uses more markdown structure and over-explains steps, while GPT-4o is more concise and uses more formal notation – traits preferred by LLM judges.</li>
                      <li><strong>Image Captioning:</strong> Comparing GPT-4V and Gemini-1.5-Flash, VibeCheck highlighted that GPT-4V focuses more on mood, storytelling, and richer descriptions, while Gemini is more literal.</li>
                   </ul>
                 </li>
              </ul>
           </div>
         </section>

         {/* Why This Matters */}
         <section className={cardStyle} style={!professionalMode ? { backgroundColor: '#FF9999' } : {}}>
           <h2 className={`text-2xl font-bold mb-4 ${professionalMode ? 'text-gray-800' : 'text-black'}`}
               style={professionalMode ? {} : { textShadow: '1px 1px 0 #00FF00' }}>
             Why This Matters
           </h2>
           <div className={`prose max-w-none ${professionalMode ? 'prose-indigo' : ''}`}>
             <p>
               Focusing on "vibes" might seem less rigorous than measuring accuracy, but these qualitative aspects clearly impact user experience and preference. VibeCheck offers a systematic way to uncover and measure these important, yet often overlooked, model characteristics. As LLMs become more integrated into various applications, understanding their vibes will be crucial for choosing the right model for the job and aligning models better with human expectations.
             </p>
              <p>
                This approach isn't limited to text; it could be extended to compare vibes in generated images, audio, or other modalities.
              </p>
           </div>
         </section>

         {/* Call to Action / Links */}
         <section className={cardStyle} style={!professionalMode ? { backgroundColor: '#FFFFFF' } : {}}>
           <h2 className={`text-2xl font-bold mb-4 ${professionalMode ? 'text-gray-800' : 'text-black'}`}
               style={professionalMode ? {} : { textShadow: '1px 1px 0 #FFA500' }}> {/* Orange shadow */}
             Check it Out!
           </h2>
           <div className={`prose max-w-none ${professionalMode ? 'prose-indigo' : ''}`}>
             <ul className="list-none pl-0 space-y-2">
                 <li>
                  Dive deeper with the full <a href="https://arxiv.org/abs/2410.12851" target="_blank" rel="noopener noreferrer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-blue-600 font-bold underline hover:text-orange-500'}`}>research paper</a>.
                 </li>
                  <li>
                   Explore the vibes yourself with our <Link to="/visualizer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-blue-600 font-bold underline hover:text-orange-500'}`}>interactive visualizer</Link>.
                 </li>
                 <li>
                   Check out the <a href="https://github.com/lisadunlap/VibeCheck" target="_blank" rel="noopener noreferrer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-blue-600 font-bold underline hover:text-orange-500'}`}>code on GitHub</a>.
                 </li>
             </ul>
           </div>
         </section>

      </main>

      {/* Footer (Reused - consider making this a shared component) */}
      <footer className={professionalMode ? "bg-gray-800 text-white py-6" : "bg-black text-white py-6 border-t-4 border-yellow-400"}>
        <div className="container mx-auto px-4 text-center">
           {professionalMode ? (
            <p>&copy; {new Date().getFullYear()} VibeCheck Team. All rights reserved.</p>
          ) : (
            <div className="scrolling-text-container overflow-hidden whitespace-nowrap">
              <div className="scrolling-text inline-block animate-scroll">
                 Bloggin' about Vibes! ✨ VibeCheck Team &copy; {new Date().getFullYear()} ✨ Keepin' it real...&nbsp;
                 Bloggin' about Vibes! ✨ VibeCheck Team &copy; {new Date().getFullYear()} ✨ Keepin' it real...&nbsp; {/* Duplicate */}
               </div>
             </div>
           )}
         </div>
       </footer>
       {/* Add CSS for scrolling animation if not already present */}
       <style jsx global>{`
         .scrolling-text-container {
           width: 100%;
         }
         @keyframes scroll {
           0% { transform: translateX(0); }
           100% { transform: translateX(-50%); }
         }
         .animate-scroll {
           animation: scroll 20s linear infinite;
         }
       `}</style>
     </div>
   );
 };

 export default BlogPage;