import React, { useState, useEffect, useMemo } from 'react';
import Papa from 'papaparse';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import rehypeRaw from 'rehype-raw';
import 'katex/dist/katex.min.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// Import the new component
import VibeCoefficientsChart from './VibeCoefficientsChart';
import VibeTable from './VibeTable';

// Main component
const LLMVibeVisualizer = () => {
  // State variables
  const [datasets] = useState(['Chatbot Arena', 'Chatbot Arena (STEM)', 'Chatbot Arena (Writing)', 'Human VS GPT', 'OOAST', 'MATH w/ CoT']);
  const [currentDataset, setCurrentDataset] = useState('Chatbot Arena');
  const [vibeScores, setVibeScores] = useState([]);
  const [examples, setExamples] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortColumn, setSortColumn] = useState('score');
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedVibe, setSelectedVibe] = useState('all');
  const [expandedVibes, setExpandedVibes] = useState({});
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [showVibeChart] = useState(true); // Remove the setter if not using it
  const [datasetModels, setDatasetModels] = useState([]);
  const [primaryModel, setPrimaryModel] = useState('');
  const [hideVibesWithLowSeparability, setHideVibesWithLowSeparability] = useState(false);
  const [datasetInfo, setDatasetInfo] = useState({});

  // Handle sort change
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };
  
  // Sort vibes based on current sort settings
  const sortedVibes = useMemo(() => {
    const sorted = [...vibeScores];
    return sorted.sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];
      
      if (sortDirection === 'asc') {
        return valueA < valueB ? -1 : 1;
      } else {
        return valueA > valueB ? -1 : 1;
      }
    });
  }, [vibeScores, sortColumn, sortDirection]);
  
  // Filter examples by selected vibe and then randomly select 50
  const filteredExamples = useMemo(() => {
    // First filter by vibe if a specific vibe is selected
    const filtered = selectedVibe === 'all' 
      ? examples 
      : examples.filter(example => example.vibe === selectedVibe);
    
    // Then randomly select 50 examples from filtered set
    const shuffled = _.shuffle(filtered);
    return shuffled.slice(0, 50);
  }, [examples, selectedVibe]);
  
  // Get unique vibes from examples
  const uniqueVibes = useMemo(() => {
    const vibes = [...new Set(examples.map(example => example.vibe))];
    return ['all', ...vibes];
  }, [examples]);

  // Toggle vibe expansion
  const toggleVibeExpansion = (index) => {
    setExpandedVibes(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  // Function to get dataset folder name from display name
  const getDatasetFolder = (displayName) => {
    if (displayName === 'Chatbot Arena') {
      return 'dataset_1';
    } else if (displayName === 'Chatbot Arena (STEM)') {
      return 'dataset_2';
    } else if (displayName === 'Chatbot Arena (Writing)') {
      return 'dataset_3';
    } else if (displayName === 'Human VS GPT') {
      return 'dataset_4';
    } else if (displayName === 'OOAST') {
      return 'dataset_5';
    } else if (displayName === 'MATH w/ CoT') {
      return 'dataset_6';
    } 
    // Fallback for any future datasets
    const datasetNumber = displayName.split(' ').pop();
    return `dataset_${datasetNumber}`;
  };

  // Fetch data based on selected dataset
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(`Starting data fetch process for ${currentDataset}...`);
        setLoading(true);
        
        // Get the dataset folder name
        const datasetFolder = getDatasetFolder(currentDataset);
        
        // Fetch models.json first to get model information
        console.log(`Attempting to read models.json from ${datasetFolder}...`);
        const modelsResponse = await fetch(`${process.env.PUBLIC_URL}/results/${datasetFolder}/models.json`);
        if (!modelsResponse.ok) {
          throw new Error(`Failed to load models info: ${modelsResponse.status} ${modelsResponse.statusText}`);
        }
        
        const modelsData = await modelsResponse.json();
        console.log('Models data:', modelsData);
        setDatasetModels(modelsData.models);
        setPrimaryModel(modelsData.models[0] || 'llama-3-70b-instruct'); // Default to first model or fallback
        setDatasetInfo(modelsData); // Store the full dataset info
        
        console.log(`Attempting to read vibe scores file from ${datasetFolder}...`);
        // Load and decompress gzipped vibe scores
        const vibeScoresResponse = await fetch(`${process.env.PUBLIC_URL}/results/${datasetFolder}/score_df.csv.gz`);
        if (!vibeScoresResponse.ok) {
          throw new Error(`Failed to load vibe scores: ${vibeScoresResponse.status} ${vibeScoresResponse.statusText}`);
        }
        
        // Decompress the gzipped content
        const vibeScoresBlob = await vibeScoresResponse.blob();
        const decompressedVibeScoresStream = vibeScoresBlob.stream().pipeThrough(new DecompressionStream('gzip'));
        const vibeScoresDecompressedBlob = await new Response(decompressedVibeScoresStream).blob();
        const vibeScoresText = await vibeScoresDecompressedBlob.text();
        
        console.log('Vibe scores text sample:', vibeScoresText.substring(0, 100));
        
        // Parse CSV
        console.log('Parsing vibe scores CSV...');
        const vibeScoresResult = Papa.parse(vibeScoresText, {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true
        });
        
        if (vibeScoresResult.errors.length > 0) {
          console.error('Parsing errors for vibe scores:', vibeScoresResult.errors);
          throw new Error('Error parsing vibe scores data');
        }
        
        console.log('Vibe scores parsed successfully:', vibeScoresResult.data.length, 'records');
        console.log('First vibe score record:', vibeScoresResult.data[0]);
        
        // Negate model ID coefficients
        const negatedVibeScores = vibeScoresResult.data.map(vibe => ({
          ...vibe,
          model_id_coefficient: vibe.model_id_coefficient * -1
        }));
        
        setVibeScores(negatedVibeScores);
        
        console.log(`Attempting to read examples file from ${datasetFolder}...`);
        // Load and decompress gzipped examples
        const examplesResponse = await fetch(`${process.env.PUBLIC_URL}/results/${datasetFolder}/per_prompt_scores.csv.gz`);
        if (!examplesResponse.ok) {
          throw new Error(`Failed to load examples: ${examplesResponse.status} ${examplesResponse.statusText}`);
        }
        
        // Decompress the gzipped content
        const examplesBlob = await examplesResponse.blob();
        const decompressedExamplesStream = examplesBlob.stream().pipeThrough(new DecompressionStream('gzip'));
        const examplesDecompressedBlob = await new Response(decompressedExamplesStream).blob();
        const examplesText = await examplesDecompressedBlob.text();
        
        console.log('Examples text sample:', examplesText.substring(0, 100));
        
        // Parse CSV
        console.log('Parsing examples CSV...');
        const examplesResult = Papa.parse(examplesText, {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true
        });
        
        if (examplesResult.errors.length > 0) {
          console.error('Parsing errors for examples:', examplesResult.errors);
          throw new Error('Error parsing examples data');
        }
        
        console.log('Examples parsed successfully:', examplesResult.data.length, 'records');
        console.log('First example record:', examplesResult.data[0]);
        setExamples(examplesResult.data);
        
        console.log('Data loading complete!');
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        console.error('Error details:', err.message);
        console.error('Error stack:', err.stack);
        
        // Check if it's a file not found error
        if (err.message && err.message.includes('404')) {
          setError('File not found. Please check if the data files exist in the correct location (public/results).');
        } else if (err.name === 'SyntaxError') {
          setError('Invalid data format. The CSV files may be corrupted or in the wrong format.');  
        } else {
          setError('Failed to load data: ' + err.message);
        }
        
        setLoading(false);
      }
    };
    
    fetchData();
  }, [currentDataset]); // Add currentDataset as dependency to reload when it changes
  
  if (loading) {
    return <div className="p-8 text-center">Loading data...</div>;
  }
  
  if (error) {
    return <div className="p-8 text-center text-red-600">{error}</div>;
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <Helmet>
        <title>LLM Vibe Visualizer</title>
        <link rel="icon" href={`${process.env.PUBLIC_URL}/favicon.ico`} />
      </Helmet>
      
      {/* Sidebar */}
      <div className={`${sidebarCollapsed ? 'w-12' : 'w-64'} bg-white shadow-md p-4 transition-all duration-300 ease-in-out relative`}>
        <button
          className="absolute top-2 right-2 p-1 rounded hover:bg-gray-200"
          onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
          title={sidebarCollapsed ? "Expand sidebar" : "Collapse sidebar"}
        >
          {sidebarCollapsed ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
            </svg>
          )}
        </button>
        
        {!sidebarCollapsed && (
          <>
            <h2 className="text-xl font-bold mb-4">Datasets</h2>
            <ul>
              {datasets.map((dataset) => (
                <li 
                  key={dataset} 
                  className={`p-2 rounded cursor-pointer ${dataset === currentDataset ? 'bg-blue-100 font-bold' : 'hover:bg-gray-100'}`}
                  onClick={() => setCurrentDataset(dataset)}
                >
                  {dataset}
                </li>
              ))}
            </ul>
          </>
        )}
        
        {sidebarCollapsed && (
          <div className="flex flex-col items-center mt-8">
            {datasets.map((dataset, index) => (
              <button
                key={dataset}
                className={`p-2 rounded-full mb-2 cursor-pointer ${dataset === currentDataset ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
                onClick={() => setCurrentDataset(dataset)}
                title={dataset}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
      </div>
      
      {/* Main Content */}
      <div className="flex-1 p-4 overflow-auto">
        {/* Navigation Header (replaced the back to landing page link) */}
        <header className="bg-white shadow-md py-4 mb-6 -m-4 mb-6">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between">
              <div className="flex items-center">
                <Link to="/" className="text-2xl font-bold">VibeCheck</Link>
                <h2 className="ml-4 text-lg text-gray-600">{currentDataset}</h2>
              </div>
              <nav className="mt-4 md:mt-0">
                <ul className="flex flex-wrap gap-6">
                  <li>
                    <Link to="/blog" className="text-blue-600 hover:underline">Blog</Link>
                  </li>
                  <li>
                    <Link to="/visualizer" className="text-blue-600 hover:underline">Vibe Visualizer</Link>
                  </li>
                  <li>
                    <a href="https://arxiv.org/abs/2410.12851" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Paper</a>
                  </li>
                  <li>
                    <a href="https://github.com/lisadunlap/VibeCheck" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">GitHub</a>
                  </li>
                  <li>
                    <a href="https://huggingface.co/datasets/lmarena-ai/Llama-3-70b-battles" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Dataset</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
        
        {/* Dataset Information Section */}
        {datasetInfo && (
          <div className="bg-white rounded-lg shadow-md p-4 mb-6">
            {datasetInfo.description && (
              <div className="mb-4">
                <div className="flex items-center mb-2">
                  <h2 className="text-lg font-semibold">Dataset Description</h2>
                  {datasetInfo.dataset_url && (
                    <a 
                      href={datasetInfo.dataset_url} 
                      className="text-blue-600 hover:underline flex items-center ml-3" 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <span>Dataset</span>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                      </svg>
                    </a>
                  )}
                </div>
                <div className="text-gray-700 prose max-w-none">
                  <ReactMarkdown 
                    remarkPlugins={[remarkMath]} 
                    rehypePlugins={[rehypeKatex, rehypeRaw]}
                  >
                    {datasetInfo.description}
                  </ReactMarkdown>
                </div>
              </div>
            )}
            
            {datasetInfo.models && datasetInfo.models.length > 0 && (
              <div className="mb-4 flex items-center flex-wrap">
                <span className="text-lg font-semibold mr-2">Models:</span>
                <div className="flex flex-wrap gap-2 mr-4">
                  {datasetInfo.models.map((model, index) => (
                    <span key={index} className={`px-3 py-1 rounded-full text-sm ${index === 1 ? 'bg-yellow-100 text-yellow-800' : 'bg-blue-100 text-blue-800'}`}>
                      {model}
                    </span>
                  ))}
                </div>
              </div>
            )}
            
            {datasetInfo.metrics && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2">
                {Object.entries(datasetInfo.metrics || {}).map(([key, value], index) => (
                  <div key={index} className="border rounded-lg p-3 bg-gray-50">
                    <h3 className="text-sm font-medium text-gray-500 mb-1">{key}</h3>
                    <p className="text-lg font-semibold">{value}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        
        {/* Vibes Table */}
        <div className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Vibe Metrics Comparison</h2>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={hideVibesWithLowSeparability}
                onChange={() => setHideVibesWithLowSeparability(prev => !prev)}
                className="sr-only peer"
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              <span className="ml-3 text-sm font-medium text-gray-700">Hide vibes with low separability (|score| &lt; 0.05)</span>
            </label>
          </div>
          <div className="bg-white p-4 rounded shadow">
            <div className="overflow-x-auto">
              <VibeTable 
                vibes={sortedVibes} 
                sortColumn={sortColumn} 
                sortDirection={sortDirection} 
                handleSort={handleSort} 
                expandedVibes={expandedVibes} 
                toggleVibeExpansion={toggleVibeExpansion} 
                hideVibesWithLowSeparability={hideVibesWithLowSeparability}
              />
            </div>
          </div>
        </div>
        
        {/* Conditionally render the VibeCoefficientsChart */}
        {showVibeChart && <VibeCoefficientsChart vibeScores={sortedVibes} />}
        
        {/* Examples Section */}
        <div className="mb-8">
          <h2 className="text-xl font-bold mb-4">Explore Examples</h2>
          <div className="bg-white p-4 rounded shadow">
            <div className="mb-4 flex items-center">
              <label htmlFor="vibe-filter" className="mr-2 font-medium">Filter by Vibe:</label>
              <select 
                id="vibe-filter"
                className="p-2 border rounded"
                value={selectedVibe}
                onChange={(e) => setSelectedVibe(e.target.value)}
              >
                {uniqueVibes.map((vibe, index) => (
                  <option key={index} value={vibe}>
                    {vibe === 'all' ? 'All Vibes' : vibe.length > 40 ? vibe.substring(0, 40) + '...' : vibe}
                  </option>
                ))}
              </select>
              <div className="ml-4 text-sm text-gray-600">
                Showing {filteredExamples.length} examples
              </div>
            </div>
            
            {filteredExamples.map((example, index) => (
              <ExampleAccordion 
                key={index}
                example={example}
                models={datasetModels}
                primaryModel={primaryModel}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// Badge component for scores and preferences
const Badge = ({ type, value, primaryModel, secondaryModel }) => {
  let bgColor = 'bg-gray-200';
  let textColor = 'text-gray-800';
  let displayValue = value;
  
  if (type === 'score') {
    // Convert score values
    if (value === 0) {
      displayValue = 'Equal';
      bgColor = 'bg-gray-100';
      textColor = 'text-gray-800';
    } else if (value > 0) {
      displayValue = primaryModel;
      bgColor = 'bg-blue-100';
      textColor = 'text-blue-800';
    } else if (value < 0) {
      displayValue = secondaryModel;
      bgColor = 'bg-yellow-200';
      textColor = 'text-yellow-800';
    } else if (value === 0) {
      displayValue = value.toFixed(2);
      bgColor = 'bg-red-100';
      textColor = 'text-red-800';
    } else {
      displayValue = value.toFixed(2);
      bgColor = 'bg-green-100';
      textColor = 'text-green-800';
    }
  } else if (type === 'preference') {
    bgColor = value === primaryModel ? 'bg-blue-100' : 'bg-yellow-200';
    textColor = value === primaryModel ? 'text-blue-800' : 'text-yellow-800';
  }
  
  return (
    <span className={`px-2 py-1 rounded-full text-xs font-medium ${bgColor} ${textColor}`}>
      {type === 'score' ? displayValue : value}
    </span>
  );
};

// Example Accordion component
const ExampleAccordion = ({ example, models, primaryModel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const title = example.question.substring(0, 50) + (example.question.length > 50 ? '...' : '');
  const secondaryModel = models.find(model => model !== primaryModel) || models[1] || "other_model";
  
  // Enhanced markdown render function with better math support
  const renderMarkdown = (content) => {
    if (!content) return <p>No content available</p>;
    
    // Pre-process the content to properly format LaTeX
    const processedContent = prepareMathContent(content);
    
    return (
      <ReactMarkdown 
        remarkPlugins={[remarkMath]} 
        rehypePlugins={[rehypeKatex, rehypeRaw]}
        components={{
          p: ({node, children}) => (
            <p className="mb-2 whitespace-pre-line">{children}</p>
          ),
          ul: ({node, children}) => (
            <ul className="list-disc pl-6 mb-4">{children}</ul>
          ),
          ol: ({node, children}) => (
            <ol className="list-decimal pl-6 mb-4">{children}</ol>
          ),
          li: ({node, children}) => (
            <li className="mb-1">{children}</li>
          ),
          code: ({node, inline, className, children, ...props}) => {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <code className={`block bg-gray-800 text-white p-2 rounded my-2 overflow-x-auto ${className}`} {...props}>
                {children}
              </code>
            ) : (
              <code className={`bg-gray-100 px-1 rounded ${className}`} {...props}>
                {children}
              </code>
            );
          },
          pre: ({node, children}) => (
            <pre className="my-2">{children}</pre>
          ),
          blockquote: ({node, children}) => (
            <blockquote className="border-l-4 border-gray-300 pl-4 py-1 my-2 text-gray-700 italic">
              {children}
            </blockquote>
          )
        }}
      >
        {processedContent}
      </ReactMarkdown>
    );
  };
  
  // Helper function to prepare content for math rendering
  const prepareMathContent = (content) => {
    if (!content) return '';
    
    // Fix common LaTeX formatting issues
    let processedContent = content;
    
    // Replace \frac{a}{b} with proper markdown math syntax
    processedContent = processedContent.replace(/\\frac\{([^{}]+)\}\{([^{}]+)\}/g, '\\frac{$1}{$2}');
    
    // Replace \begin{align*} and \end{align*} with proper markdown math delimiters
    processedContent = processedContent.replace(/\\begin\{align\*\}/g, '$$');
    processedContent = processedContent.replace(/\\end\{align\*\}/g, '$$');
    
    // Ensure inline math expressions have proper spacing
    processedContent = processedContent.replace(/\$([^$]+)\$/g, ' $$$1$$ ');
    
    // Handle escaped dollar signs that should be rendered as actual dollar signs
    processedContent = processedContent.replace(/\\\$/g, '$');
    
    // Handle LaTeX commands that might be causing issues
    processedContent = processedContent.replace(/\\cdot/g, '\\cdot ');
    processedContent = processedContent.replace(/\\boxed/g, '\\boxed');
    
    return processedContent;
  };
  
  return (
    <div className="border-b last:border-b-0">
      <div 
        className="flex items-center justify-between p-4 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex-1 font-medium">
          {title}
        </div>
        <div className="flex items-center space-x-4 text-sm">
          <div>
            <span className="font-semibold mr-2">Score:</span>
            <Badge type="score" value={example.score} primaryModel={primaryModel} secondaryModel={secondaryModel} />
          </div>
          <div>
            <span className="font-semibold mr-2">Preference:</span>
            <Badge type="preference" value={example.preference} primaryModel={primaryModel} />
          </div>
          <div className="text-blue-500">
            {isOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            )}
          </div>
        </div>
      </div>
      
      {isOpen && (
        <div className="p-4 bg-gray-50 rounded-b">
          <h4 className="font-semibold mb-2">Question:</h4>
          <div className="mb-4 whitespace-pre-wrap math-content">
            {renderMarkdown(example.question)}
          </div>
          
          <div className="mb-4">
            <div className="font-semibold text-sm px-2 py-1 bg-blue-200 inline-block rounded-t border border-blue-300 border-b-0">
              {primaryModel}
            </div>
            <div className="p-3 bg-blue-50 rounded-b rounded-tr border border-blue-100">
              <div className="markdown-content prose max-w-none math-content">
                {renderMarkdown(example[primaryModel])}
              </div>
            </div>
          </div>
          
          <div className="mb-4">
            <div className="font-semibold text-sm px-2 py-1 bg-yellow-200 inline-block rounded-t border border-yellow-300 border-b-0">
              {secondaryModel}
            </div>
            <div className="p-3 bg-yellow-50 rounded-b rounded-tr border border-yellow-100">
              <div className="markdown-content prose max-w-none math-content">
                {renderMarkdown(example[secondaryModel] || example["not_llama"])}
              </div>
            </div>
          </div>
          
          <div className="flex items-center mt-4 p-2 bg-white rounded">
            <div className="flex-1">
              <span className="font-semibold">Vibe: </span> 
              <div className="markdown-content prose max-w-none inline math-content">
                {renderMarkdown(example.vibe)}
              </div>
            </div>
            <div className="ml-4">
              <span className="font-semibold">Score: </span> 
              <Badge type="score" value={example.score} primaryModel={primaryModel} secondaryModel={secondaryModel} />
            </div>
            <div className="ml-4">
              <span className="font-semibold">Preferred Model: </span> 
              <Badge type="preference" value={example.preference} primaryModel={primaryModel} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LLMVibeVisualizer;